export const DEFAULT_THUMBNAIL_DIMENSIONS = {
  height: 48,
  width: 80,
};

const PADDING = 4;
export const BOARD_THUMBNAIL_DIMENSIONS = {
  height: DEFAULT_THUMBNAIL_DIMENSIONS.height - PADDING * 2,
  width: DEFAULT_THUMBNAIL_DIMENSIONS.width - PADDING * 2,
};
