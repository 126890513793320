import { parseISO } from 'date-fns';
import { isDate, isNumber, isString } from 'lodash';

import { isChromeIos } from './BrowserUtils';

export const DownloadFile = (link: string) => {
  const isChromeIOS = isChromeIos();

  if (isChromeIOS) {
    /**
     * @description For chrome ios we have to rely on the "Open in" button and we can do that by opening the file in a new tab. This isn't ideal on desktop because it would only open the file in the new tab. Conversely, on mobile Chrome, the "Open in"/"Download" button would immediately become visible (this does not happen on desktop)
     * @see https://stackoverflow.com/a/12365583
     */
    window.open(link, '_blank');
  } else {
    window.location.href = link;
  }
};

export const BulkDownloadFiles = (links: string[]) => {
  const fragment = document.createDocumentFragment();
  const iframes: HTMLDivElement[] = [];

  links.forEach((link) => {
    const iframe = document.createElement('iframe');

    iframe.src = link;
    iframe.style.display = 'none';
    iframes.push(iframe);
    fragment.appendChild(iframe);
  });

  document.body.appendChild(fragment);

  window.setTimeout(() => {
    iframes.forEach((iframe) => {
      document.body.removeChild(iframe);
    });
  }, 60 * 1000);
};

/** Use this function to tell if an S3 download link has expired or not before the user tries to download */
export const isS3DownloadUrlActive = (url: string) => {
  const parsedValues = new URLSearchParams(new URL(url).search);
  const parsedDate = parsedValues.get('X-Amz-Date');
  const downloadDate = isString(parsedDate) ? parseISO(parsedDate) : null;
  const parsedExpiration = parsedValues.get('X-Amz-Expires');
  const parsedExpirationNumberInSeconds = isString(parsedExpiration) ? parseInt(parsedExpiration) : null;
  const expirationDate =
    isDate(downloadDate) && isNumber(parsedExpirationNumberInSeconds)
      ? new Date(downloadDate.getTime() + parsedExpirationNumberInSeconds * 1000)
      : null;

  const expirationDateTime = expirationDate?.getTime();
  const now = Date.now();

  return !!expirationDateTime && expirationDateTime > now;
};
