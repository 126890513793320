import { Members } from "@air/api";
import { WorkspaceMemberType } from "@air/api/types";
import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import { useCurrentWorkspacePermissionsContext } from "~/providers/CurrentWorkspacePermissionsProvider";
import { useCurrentWorkspace } from "~/providers/CurrentWorkspaceProvider";
import { canSeeWorkspaceMembers } from "~/utils/permissions/workspacePermissions";
export const MEMBERS_LIST = "MEMBERS_LIST";
export const getWorkspaceMembersKey = (workspaceId)=>{
    return [
        MEMBERS_LIST,
        {
            workspaceId
        }
    ];
};
export const useGetWorkspaceMembers = ()=>{
    const { currentWorkspace } = useCurrentWorkspace();
    const { data: permissions } = useCurrentWorkspacePermissionsContext();
    return useQuery({
        queryKey: getWorkspaceMembersKey(currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id),
        queryFn: async ()=>{
            const workspaceId = currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.id;
            invariant(workspaceId, "No workspace id");
            const { members } = await Members.list({
                workspaceId,
                options: {
                    memberTypes: [
                        WorkspaceMemberType.sysadmin,
                        WorkspaceMemberType.member,
                        WorkspaceMemberType.guest
                    ]
                }
            });
            return members;
        },
        staleTime: Infinity,
        enabled: !!currentWorkspace && canSeeWorkspaceMembers(permissions)
    });
};
