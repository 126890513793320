import { WorkspaceMemberType } from "@air/api/types";
import { useMemo } from "react";
import { useSubscriptionContext } from "~/providers/SubscriptionProvider";
import { useWorkspaceRolesContext } from "~/providers/WorkspaceRolesProvider";
import { isActiveMember, isGuest } from "~/swr-hooks/members/utils/memberUtils";
import { useCurrentWorkspaceMember } from "./useCurrentWorkspaceMember";
import { useFetchMembers } from "./useFetchMembers";
import { sortMembersByRole } from "./utils/sortMembersByRole";
export const useWorkspaceMembers = ()=>{
    const { data: members } = useFetchMembers();
    const { data: roles } = useWorkspaceRolesContext();
    const { data: subscription } = useSubscriptionContext();
    const { currentWorkspaceMember } = useCurrentWorkspaceMember();
    const allMembers = useMemo(()=>members && roles ? sortMembersByRole(members, roles).filter((member)=>member.type === WorkspaceMemberType.member && !member.inactive) : [], [
        members,
        roles
    ]);
    const inactiveMembers = useMemo(()=>{
        var _members_filter;
        return (_members_filter = members === null || members === void 0 ? void 0 : members.filter((member)=>member.inactive)) !== null && _members_filter !== void 0 ? _members_filter : [];
    }, [
        members
    ]);
    const activeMembers = useMemo(()=>allMembers === null || allMembers === void 0 ? void 0 : allMembers.filter((member)=>isActiveMember(member)), [
        allMembers
    ]);
    const pendingMembers = useMemo(()=>allMembers === null || allMembers === void 0 ? void 0 : allMembers.filter((member)=>member.pending), [
        allMembers
    ]);
    const billableMembers = useMemo(()=>allMembers === null || allMembers === void 0 ? void 0 : allMembers.filter((member)=>member.isBilled && !member.inactive), [
        allMembers
    ]);
    const allGuests = useMemo(()=>members && roles ? sortMembersByRole(members, roles).filter((member)=>isGuest(member)) : [], [
        members,
        roles
    ]);
    const activeGuests = useMemo(()=>allGuests === null || allGuests === void 0 ? void 0 : allGuests.filter((member)=>!member.inactive && !member.pending), [
        allGuests
    ]);
    const pendingGuests = useMemo(()=>allGuests === null || allGuests === void 0 ? void 0 : allGuests.filter((member)=>member.pending), [
        allGuests
    ]);
    const billableGuests = useMemo(()=>allGuests === null || allGuests === void 0 ? void 0 : allGuests.filter((member)=>member.isBilled), [
        allGuests
    ]);
    const sysAdminMembers = useMemo(()=>{
        var _ref;
        return (_ref = (currentWorkspaceMember === null || currentWorkspaceMember === void 0 ? void 0 : currentWorkspaceMember.type) !== WorkspaceMemberType.sysadmin ? [] : members === null || members === void 0 ? void 0 : members.filter((member)=>member.type === WorkspaceMemberType.sysadmin)) !== null && _ref !== void 0 ? _ref : [];
    }, [
        currentWorkspaceMember === null || currentWorkspaceMember === void 0 ? void 0 : currentWorkspaceMember.type,
        members
    ]);
    const maxMembers = useMemo(()=>subscription === null || subscription === void 0 ? void 0 : subscription.maxMembers, [
        subscription
    ]);
    return {
        members: members ? {
            allMembers,
            activeMembers,
            inactiveMembers,
            pendingMembers,
            billableMembers,
            allGuests,
            activeGuests,
            pendingGuests,
            billableGuests,
            sysAdminMembers,
            maxMembers
        } : undefined
    };
};
