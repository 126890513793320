import { BoardsListOptions, Library, SortDirection, SubnavBoardSort } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';

import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';
import { useSubnavSort } from '~/swr-hooks/subnav/useSubnavSort';

export const LIBRARY_BOARDS_LIST = 'LIBRARY_BOARDS_LIST';

export const getLibraryRootBoardsKey = (params: {
  workspaceId?: string;
  sortField?: SubnavBoardSort;
  libraryId: Library['id'] | undefined;
  params?: Partial<BoardsListOptions>;
}) => {
  return [LIBRARY_BOARDS_LIST, params];
};

export const useLibraryRootBoards = (
  workspaceId: string | undefined,
  libraryId: Library['id'] | undefined,
  params?: Partial<BoardsListOptions>,
) => {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();
  const { data: subnavSort } = useSubnavSort();
  const sortField = subnavSort?.boardSort;

  const queryParams: Partial<BoardsListOptions> = {
    isMemberOfBoard: 'ALL',
    limit: 100,
    view: params?.search ? 'all' : 'root',
    includeAncestors: true,
    numThumbnails: 0,
    sortField: sortField ? { name: sortField.name, direction: sortField.direction } : undefined,
    libraryBoards: 'YES',
    libraryIds: libraryId ? [libraryId] : undefined,
    ...params,
  };

  const inifiteQuery = useInfiniteQuery({
    queryKey: getLibraryRootBoardsKey({
      workspaceId,
      sortField,
      libraryId,
      params: queryParams,
    }),
    queryFn: ({ pageParam }) =>
      privateBoardsFetcher({
        params: queryParams,
        sortFieldName: sortField?.name || 'customSort',
        sortDirection: sortField?.direction || SortDirection.desc,
        cursor: pageParam || null,
      }),

    enabled: !!workspaceId && !!libraryId,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  return {
    data: inifiteQuery.data?.pages.flatMap((d) => d.data) ?? [],
    hasMore: !!inifiteQuery.hasNextPage,
    loadNextPage: inifiteQuery.fetchNextPage,
    isLoadingMore: inifiteQuery.isFetchingNextPage,
    isInitialLoading: inifiteQuery.isLoading,
  };
};
