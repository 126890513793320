import { GetFromObjectsParams, Permissions, ScopedPermissions } from '@air/api';
import { permissionsSelector, setPermissionsAction } from '@air/redux-permissions';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { isArray, isString } from 'lodash';
import { useDispatch } from 'react-redux';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useAirStore } from '~/utils/ReduxUtils';

function isEmpty(obj: GetFromObjectsParams<'all'>['objects']): boolean {
  return !(Object.keys(obj) as (keyof typeof obj)[]).some((key) => {
    const value = obj[key];

    if (typeof value === 'string') {
      return value !== '';
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return value !== undefined;
  });
}

export interface UseFetchPermissionsParams {
  objects: GetFromObjectsParams<'all'>['objects'];
  shortId?: string;
  excludeFetched?: boolean;
  options?: Omit<
    UseQueryOptions<
      { [key: string]: ScopedPermissions },
      unknown,
      { [key: string]: ScopedPermissions },
      ReturnType<typeof getFetchPermissionsKey>
    >,
    'queryKey'
  >;
}

export const OBJECTS_PERMISSIONS = 'OBJECTS_PERMISSIONS';

export const getFetchPermissionsKey = (objects: UseFetchPermissionsParams['objects'], shortId?: string) => [
  OBJECTS_PERMISSIONS,
  { objects, shortId },
];

export const useFetchObjectsPermissions = ({
  objects,
  shortId,
  excludeFetched = true,
  options = {},
}: UseFetchPermissionsParams) => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const key = getFetchPermissionsKey(objects, shortId);
  const { currentWorkspace } = useCurrentWorkspace();

  const _objects = excludeFetched
    ? (Object.keys(objects) as (keyof typeof objects)[]).reduce<typeof objects>((acc, scope) => {
        const value = objects[scope];

        if (isArray(value) && value.length > 0) {
          return {
            ...acc,
            [scope]: value.filter((objectId) => !permissionsSelector(store.getState(), scope, objectId)),
          };
        } else if (isString(value)) {
          const permissions = permissionsSelector(store.getState(), scope, value);

          return {
            ...acc,
            [scope]: !permissions ? value : undefined,
          };
        }

        return {
          ...acc,
          [scope]: value,
        };
      }, {})
    : objects;

  return useQuery({
    queryKey: key,

    queryFn: async () => {
      invariant(currentWorkspace?.id || shortId, 'No current workspace or short id');
      const permissions = await Permissions.getFromObjects({
        ...(shortId ? { shortId } : { workspaceId: currentWorkspace!.id }),
        objects: _objects,
        mode: 'all',
      });

      /**
       * We iterate over all of the object scopes with values and set the query data for each.
       */
      (Object.keys(_objects) as (keyof typeof _objects)[]).forEach((scope) => {
        const value = _objects[scope];

        if (isArray(value) && value.length > 0) {
          dispatch(
            setPermissionsAction({
              [scope]: value.reduce<{
                [key: string]: ScopedPermissions;
              }>((curr, objectId) => {
                curr[objectId] = permissions[objectId];

                return curr;
              }, {}),
            }),
          );
        } else if (isString(value)) {
          const objectPermissions = permissions[value];

          dispatch(
            setPermissionsAction({
              [scope]: {
                [value]: objectPermissions,
              },
            }),
          );
        }
      });

      return permissions;
    },
    ...options,
    enabled: !isEmpty(_objects) && !!(options?.enabled ?? true) && !!(currentWorkspace?.id || shortId),
  });
};
