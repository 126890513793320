import { ScopedPermissions } from '@air/api';
import { permissionsSelector, setPermissionsAction } from '@air/redux-permissions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAirStore } from '~/utils/ReduxUtils';

export const useBoardPermissionsCache = () => {
  const store = useAirStore();
  const dispatch = useDispatch();

  const getBoardPermissions = useCallback(
    (boardId: string) => permissionsSelector(store.getState(), 'boardIds', boardId),
    [store],
  );

  const setBoardPermissions = useCallback(
    ({ boardId, permissions }: { boardId: string; permissions: ScopedPermissions | undefined }) =>
      dispatch(
        setPermissionsAction({
          boardIds: {
            [boardId]: permissions,
          },
        }),
      ),
    [dispatch],
  );

  return {
    getBoardPermissions,
    setBoardPermissions,
  };
};
