import { Subscriptions } from '@air/api';
import { BYTES_TERABYTE, formatBytes } from '@air/utilities';
import { useQuery } from '@tanstack/react-query';
import { isString, isUndefined } from 'lodash';

import { ONE_DAY } from '~/constants/unitsOfTime';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeePrivateWorkspaceDetails } from '~/utils/permissions/workspacePermissions';

export const WORKSPACE_STORAGE = 'WORKSPACE_STORAGE';

export const getWorkspaceStorageKey = (workspaceId?: string) => {
  return [WORKSPACE_STORAGE, { workspaceId }];
};

export const useStorage = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  return useQuery({
    queryKey: getWorkspaceStorageKey(currentWorkspace?.id),

    queryFn: async () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const subscription = await Subscriptions.get({ workspaceId });

      const { maxStorage, maxGb } = subscription;
      /**
       * The backend is currently sending down a string for the usedStorage instead of a number sometimes (it has to do with caching).
       */
      const usedStorage = isString(subscription.usedStorage)
        ? parseInt(subscription.usedStorage)
        : subscription.usedStorage;

      const STORAGE_THRESHOLD = 0.9;

      return {
        maxStorage,
        maxGb,
        usedStorage,
        isOutOfStorage: maxStorage === null || usedStorage < maxStorage ? false : true,
        isAlmostOutOfStorage: maxStorage === null ? false : Boolean(usedStorage >= STORAGE_THRESHOLD * maxStorage),
        isOver1TBUsedStorage: usedStorage >= BYTES_TERABYTE,
        formattedUsedBytes: formatBytes(usedStorage, { numberOfDecimals: 1 }),
        formattedUsedBytesAsGigabytes: formatBytes(usedStorage, { overrideUnit: 'GB' }),
        formattedMaxBytes: maxStorage ? formatBytes(maxStorage) : undefined,
      };
    },
    staleTime: ONE_DAY,
    enabled: !isUndefined(currentWorkspace) && canSeePrivateWorkspaceDetails(permissions),
  });
};
