import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { useCallback } from 'react';

import { getDropdownMenuOption } from '~/utils/menuOptions/getDropdownMenuOption';

interface GetPrivateBoardMenuOptionsParams {
  /** Callback for when the user selects to move the board */
  onMove?: () => void;
  /** Callback for when the user selects to favorite/unfavorite the board */
  onFavorite?: () => void;
  /** Callback for when the user selects to favorite/unfavorite the board */
  onUnfavorite?: () => void;
  /** Callback for when the user selects to share the board */
  onShare?: () => void;
  /** Callback for when the user selects to edit the board details */
  onEdit?: () => void;
  /** Callback for when the user selects to edit the board details inline */
  onEditInline?: () => void;
  /** Callback for when the user selects to add a subboard to the board */
  onAddSubBoard?: () => void;
  /** Callback for when the user selects to download the board */
  onDownload?: () => void;
  /** Callback for when the user selects to delete the board */
  onDelete?: () => void;
  /** Callback for when the user selects to duplicate the board */
  onDuplicate?: () => void;
  /** Callback for when the user selects to rename the board */
  onRename?: () => void;
  customFieldsMenuOptions?: DropdownMenuOption[];
  /**Callback for when the user selects Connect to Slack */
  onConnectToSlack?: () => void;
  onConnectedToSlack?: {
    onSelect: () => void;
    description: string;
  };
  onOpenOnDesktop?: () => void;
}

/**
 * Use this utility to build out the order of the options you'd see in an ellipsis menu for a board. Just pass the functions you want, with the board (and it's permissions).
 */
export const useGetPrivateBoardMenuOptions = () => {
  const getBoardMenuOptions = useCallback(
    ({
      onMove,
      onFavorite,
      onUnfavorite,
      onShare,
      onEdit,
      onEditInline,
      onAddSubBoard,
      onDownload,
      onDelete,
      onDuplicate,
      onRename,
      customFieldsMenuOptions,
      onConnectToSlack,
      onConnectedToSlack,
      onOpenOnDesktop,
    }: GetPrivateBoardMenuOptionsParams) => {
      const options: DropdownMenuOption[] = [];

      onShare && options.push({ ...getDropdownMenuOption('share'), onSelect: onShare, type: 'item' });

      onFavorite && options.push({ ...getDropdownMenuOption('favorite'), onSelect: onFavorite, type: 'item' });

      onUnfavorite && options.push({ ...getDropdownMenuOption('unfavorite'), onSelect: onUnfavorite, type: 'item' });

      onDownload && options.push({ ...getDropdownMenuOption('download'), onSelect: onDownload, type: 'item' });

      onOpenOnDesktop &&
        options.push({ ...getDropdownMenuOption('open-on-desktop'), onSelect: onOpenOnDesktop, type: 'item' });

      onMove &&
        options.push(
          {
            id: 'move-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('move'),
            onSelect: onMove,
            type: 'item',
          },
        );

      onAddSubBoard &&
        options.push({ ...getDropdownMenuOption('add-sub-board'), onSelect: onAddSubBoard, type: 'item' });

      onEdit &&
        options.push({
          ...getDropdownMenuOption('edit'),
          onSelect: onEdit,
          type: 'item',
        });

      onEditInline &&
        options.push({
          ...getDropdownMenuOption('edit-inline'),
          onSelect: onEditInline,
          type: 'item',
        });

      onRename && options.push({ ...getDropdownMenuOption('rename'), onSelect: onRename, type: 'item' });

      customFieldsMenuOptions &&
        customFieldsMenuOptions.length > 0 &&
        options.push({
          ...getDropdownMenuOption('edit-custom-fields'),
          options: customFieldsMenuOptions,
          type: 'sub',
        });

      onDuplicate &&
        options.push({
          ...getDropdownMenuOption('duplicate'),
          onSelect: onDuplicate,
          type: 'item',
        });

      onConnectToSlack &&
        options.push(
          {
            id: 'connect-to-slack-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('connect-to-slack'),
            onSelect: onConnectToSlack,
            type: 'item',
          },
        );

      onConnectedToSlack &&
        options.push(
          {
            id: 'connnected-to-slack-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('connected-to-slack'),
            onSelect: onConnectedToSlack.onSelect,
            description: onConnectedToSlack.description,
            type: 'item',
          },
        );

      onDelete &&
        options.push(
          {
            id: 'delete-separator',
            type: 'separator',
          },
          {
            ...getDropdownMenuOption('delete'),
            onSelect: onDelete,
            type: 'item',
            className: 'text-red-11',
          },
        );

      return options;
    },
    [],
  );

  return { getBoardMenuOptions };
};
