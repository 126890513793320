import { subscriptionPlanMap } from "~/utils/SubscriptionUtils";
import { enterpriseOptions } from "../EnterprisePlanModal";
const getPlanTextMap = (pricingModel, fixedPricingFlag)=>{
    return {
        basic: {
            tagline: "For individuals and small projects",
            priceDetail: "Forever",
            options: [
                "5 GB per workspace",
                "2 members",
                "50 boards",
                "7 day deletion recovery window",
                "GDrive, Dropbox & Box import",
                "Commenting and notifications",
                "Support for all file types",
                "PDF previews"
            ]
        },
        plus: {
            tagline: "Perfect for small teams with simple workflows",
            priceDetail: pricingModel === "fixed" ? "per month" : "per member per month",
            options: [
                ...fixedPricingFlag ? [
                    "300 GB per workspace",
                    "5 members",
                    "250 boards"
                ] : [
                    "200 GB per workspace",
                    "Unlimited members (pay per seat)"
                ],
                "30 day deletion recovery window",
                "GDrive, Dropbox & Box import",
                "Commenting and notifications",
                "Support for all file types",
                "PDF previews"
            ]
        },
        pro: {
            tagline: "For small teams working with external partners",
            priceDetail: pricingModel === "fixed" ? "per month" : "per member per month",
            options: [
                ...fixedPricingFlag ? [
                    "3TB per workspace",
                    "Unlimited members & guests",
                    "1000 boards"
                ] : [
                    "2TB per workspace"
                ],
                "30 day deletion recovery window",
                "Passcode-protected links",
                "Guest members",
                "Comment-only members"
            ]
        },
        enterprise: {
            tagline: "For medium to large teams with 20+ members",
            customPriceDescription: "Let's chat!",
            options: enterpriseOptions
        }
    };
};
const getStripePlanIds = (billingCycles)=>{
    var _billingCycles_find, _billingCycles_find1, _billingCycles_find2, _billingCycles_find3;
    return {
        annual: billingCycles === null || billingCycles === void 0 ? void 0 : (_billingCycles_find = billingCycles.find((param)=>{
            let { id } = param;
            return id === "annual";
        })) === null || _billingCycles_find === void 0 ? void 0 : _billingCycles_find.stripeId,
        monthly: billingCycles === null || billingCycles === void 0 ? void 0 : (_billingCycles_find1 = billingCycles.find((param)=>{
            let { id } = param;
            return id === "monthly";
        })) === null || _billingCycles_find1 === void 0 ? void 0 : _billingCycles_find1.stripeId,
        quarter: billingCycles === null || billingCycles === void 0 ? void 0 : (_billingCycles_find2 = billingCycles.find((param)=>{
            let { id } = param;
            return id === "quarter";
        })) === null || _billingCycles_find2 === void 0 ? void 0 : _billingCycles_find2.stripeId,
        semiAnnual: billingCycles === null || billingCycles === void 0 ? void 0 : (_billingCycles_find3 = billingCycles.find((param)=>{
            let { id } = param;
            return id === "semiAnnual";
        })) === null || _billingCycles_find3 === void 0 ? void 0 : _billingCycles_find3.stripeId
    };
};
export const planPricingToPlanInfo = (plan, currentPlan, fixedPricingFlag)=>{
    const { id, billingCycles, isCurrentPlan, pricingModel } = plan;
    const prices = {
        annual: 0,
        monthly: 0,
        quarter: 0,
        semiAnnual: 0
    };
    const annualPrice = billingCycles === null || billingCycles === void 0 ? void 0 : billingCycles.find((param)=>{
        let { id } = param;
        return id === "annual";
    });
    const monthly = billingCycles === null || billingCycles === void 0 ? void 0 : billingCycles.find((param)=>{
        let { id } = param;
        return id === "monthly";
    });
    if (annualPrice) {
        prices.annual = Math.round(annualPrice.planPrice / 12);
    }
    if (monthly) {
        prices.monthly = monthly.planPrice;
    }
    const stripePlanIds = getStripePlanIds(billingCycles);
    const planTextMap = getPlanTextMap(pricingModel, fixedPricingFlag);
    const isPro = id === "pro";
    const isEnterprise = id === "enterprise";
    return {
        id,
        stripePlanIds,
        tagline: planTextMap[id].tagline,
        prices: prices,
        priceDetail: planTextMap[id].priceDetail,
        customPriceDescription: planTextMap[id].customPriceDescription,
        isCurrentPlan: currentPlan === "enterprise" && isEnterprise ? true : isCurrentPlan,
        showRightBorder: id !== "enterprise",
        optionsListHeader: isPro ? "Everything in Plus, and:" : isEnterprise ? "Everything in Pro, and:" : "".concat(subscriptionPlanMap[id], " workspaces include:"),
        options: planTextMap[id].options
    };
};
