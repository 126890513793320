import { VIDEO_SPRITESHEET_COLUMN_COUNT, VIDEO_SPRITESHEET_FRAME_COUNT, VIDEO_SPRITESHEET_ROW_COUNT } from "@air/component-video";
import { useCallback, useMemo, useRef } from "react";
export const SCRUBBABLE_THUMBNAIL_INDICATOR_WIDTH = 2;
export const SCRUBBABLE_THUMBNAIL_OFFSET = 8;
export const useVideoScrubbableThumbnail = (param)=>{
    let { aspectRatio, containerDimensions } = param;
    const indicatorRef = useRef(null);
    const backgroundImageRef = useRef(null);
    const dimensions = useMemo(()=>{
        if (aspectRatio > 1) {
            const scaledHeight = containerDimensions.height;
            const scaledWidth = scaledHeight * aspectRatio;
            if (scaledWidth < containerDimensions.width) {
                return {
                    aspectRatio,
                    scaledHeight: containerDimensions.width / aspectRatio,
                    scaledWidth: containerDimensions.width
                };
            }
            return {
                aspectRatio,
                scaledHeight,
                scaledWidth
            };
        }
        const scaledWidth = containerDimensions.width;
        const scaledHeight = scaledWidth * aspectRatio;
        if (scaledHeight > containerDimensions.height) {
            return {
                aspectRatio,
                scaledHeight: containerDimensions.height,
                scaledWidth: containerDimensions.height * aspectRatio
            };
        }
        return {
            aspectRatio,
            scaledHeight: scaledWidth / aspectRatio,
            scaledWidth
        };
    }, [
        aspectRatio,
        containerDimensions.height,
        containerDimensions.width
    ]);
    const onMouseMove = useCallback((event)=>{
        const { clientX, currentTarget } = event;
        const { left, width: containerWidth } = currentTarget.getBoundingClientRect();
        const pos = Math.max(0, Math.min(1, (clientX - left) / containerDimensions.width));
        const currentFrame = Math.floor(pos * VIDEO_SPRITESHEET_FRAME_COUNT);
        const currentRow = Math.min(VIDEO_SPRITESHEET_ROW_COUNT - 1, Math.floor(currentFrame / (VIDEO_SPRITESHEET_FRAME_COUNT / VIDEO_SPRITESHEET_ROW_COUNT)));
        const isLastFrame = currentFrame === VIDEO_SPRITESHEET_FRAME_COUNT;
        const currentColumn = isLastFrame ? VIDEO_SPRITESHEET_COLUMN_COUNT - 1 : currentFrame % VIDEO_SPRITESHEET_COLUMN_COUNT;
        /**
       * Updates the indicator position
       */ if (indicatorRef.current) {
            indicatorRef.current.style.display = "block";
            indicatorRef.current.style.transform = "translateX(".concat(pos * containerWidth - (isLastFrame ? SCRUBBABLE_THUMBNAIL_INDICATOR_WIDTH : 0), "px)");
        }
        /**
       * Updates the background position to show the current frame
       */ if (backgroundImageRef.current) {
            const columnPosition = currentColumn * dimensions.scaledWidth;
            const rowPosition = currentRow * dimensions.scaledHeight;
            backgroundImageRef.current.style.backgroundPosition = "".concat(-columnPosition, "px ").concat(-rowPosition, "px");
        }
    }, [
        containerDimensions.width,
        dimensions.scaledHeight,
        dimensions.scaledWidth
    ]);
    const onMouseLeave = useCallback(()=>{
        /**
     * Resets the background position back to the first frame
     */ if (backgroundImageRef.current) {
            backgroundImageRef.current.style.backgroundPosition = "0 0";
        }
        /**
     * Hides the indicator and resets its position
     */ if (indicatorRef.current) {
            indicatorRef.current.style.display = "none";
            indicatorRef.current.style.transform = "translateX(0px)";
        }
    }, []);
    const backgroundImageStyles = useMemo(()=>{
        const isHorizontal = dimensions.aspectRatio > 1;
        const backgroundSize = isHorizontal ? "".concat(dimensions.scaledWidth * VIDEO_SPRITESHEET_COLUMN_COUNT, "px auto") : "auto ".concat(dimensions.scaledHeight * VIDEO_SPRITESHEET_ROW_COUNT, "px");
        const transform = isHorizontal ? "translateX(".concat((containerDimensions.width - dimensions.scaledWidth) / 2, "px)") : "translateY(".concat((containerDimensions.height - dimensions.scaledHeight) / 2, "px)");
        return {
            backgroundSize,
            height: dimensions.scaledHeight,
            transform,
            width: dimensions.scaledWidth
        };
    }, [
        containerDimensions.height,
        containerDimensions.width,
        dimensions.aspectRatio,
        dimensions.scaledHeight,
        dimensions.scaledWidth
    ]);
    return {
        backgroundImageRef,
        backgroundImageStyles,
        indicatorRef,
        onMouseLeave,
        onMouseMove
    };
};
