import { type Clip } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { loadHls } from '@air/utils-video';
import Hls from 'hls.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type UseVideoHlsPreviewParams = {
  clip: Pick<Clip, 'assets' | 'id' | 'type'>;
};

export const useVideoHlsPreview = ({ clip }: UseVideoHlsPreviewParams) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const hlsRef = useRef<Hls | null>(null);
  const hlsStateRef = useRef<'loading' | 'loaded' | 'idle'>('idle');
  const [currentVideoSrc, setCurrentVideoSrc] = useState<string | null>(null);

  const videoSrc = useMemo(() => {
    // TODO: Backend needs to be sending us the v4. This should future-proof it for now.
    if (!clip.assets.seekVideo) return undefined;

    if (clip.assets.seekVideo.includes('preview_v4')) return clip.assets.seekVideo;

    return clip.assets.seekVideo.replace(/preview?\w*/, 'preview_v4');
  }, [clip.assets.seekVideo]);

  useEffect(() => {
    if (hlsStateRef.current) {
      hlsStateRef.current = 'idle';
    }

    hlsRef.current?.destroy();

    return () => {
      hlsRef.current?.destroy();
    };
  }, [clip.id]);

  useEffect(() => {
    if (currentVideoSrc !== videoSrc) {
      hlsStateRef.current = 'idle';
    }
  }, [currentVideoSrc, videoSrc]);

  const onUpdateVideo = useCallback(
    async ({ duration, position }: { duration: number; position: number }) => {
      if (videoRef.current && videoSrc && (hlsStateRef.current === 'idle' || currentVideoSrc !== videoSrc)) {
        videoRef.current.src = videoSrc;
        setCurrentVideoSrc(videoSrc);
        if (clip.type === 'video') {
          try {
            hlsRef.current?.destroy();
            hlsStateRef.current = 'loading';
            hlsRef.current = await loadHls({
              src: videoSrc,
              videoElement: videoRef.current,
            });
          } catch (error) {
            reportErrorToBugsnag({
              error,
              context: 'Failed to load new Hls source in Seek Bar',
              metadata: {
                key: 'Data',
                data: { clip },
              },
            });
          }
        }
      }

      hlsStateRef.current = 'loaded';

      const newTime = position * duration;

      if (videoRef.current) {
        videoRef.current.currentTime = newTime;
      }
    },
    [clip, currentVideoSrc, videoSrc],
  );

  return {
    hlsRef,
    videoRef,
    onUpdateVideo,
  };
};
