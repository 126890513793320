import { useTrackMovedBoards } from '@air/analytics/src/events/useTrackMovedBoards';
import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { moveBoardRequestAction } from '~/store/centralizedBoard/actions';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { useAddBoardsToAllViews, useRemoveBoardsFromAllViews } from '~/utils/mutateUtils/GalleryBoards';
import { useChangeSideBoardsParent } from '~/utils/mutateUtils/SideNavBoards';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export interface MoveItemsToBoardParams {
  newParentId: string | null;
  boards: Board[];
  library?: Board['library'];
}

export const useMoveBoards = () => {
  const dispatch = useDispatch();
  const { updateBoardStats } = useUpdateBoardStats();
  const { removeBoardsFromAllViews } = useRemoveBoardsFromAllViews();
  const { addBoardsToAllViews } = useAddBoardsToAllViews();
  const { changeSideBoardsParent } = useChangeSideBoardsParent();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackMovedBoards } = useTrackMovedBoards();

  const workspaceId = currentWorkspace?.id;

  const handleBoardMove = useCallback(
    ({
      boards,
      newParentId,
      oldParentId,
      oldLibraryId,
      newLibrary,
    }: {
      boards: Board[];
      newParentId: string | null;
      oldParentId: string | null;
      oldLibraryId: string | undefined;
      newLibrary?: Board['library'];
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const boardsToMove = boards.map((board) => ({
        ...board,
        parentId: newParentId,
        ancestors: newParentId ? board.ancestors : [],
        library: newLibrary,
      }));

      dispatch(
        moveBoardRequestAction({
          boards: boardsToMove,
          newParentId,
          oldParentId,
        }),
      );

      changeSideBoardsParent(workspaceId, boardsToMove, oldParentId, oldLibraryId);

      addBoardsToAllViews({
        parentBoardId: newParentId ?? undefined,
        boardsToAdd: boardsToMove,
        libraryId: newLibrary?.id,
      });

      const boardsIdsToRemove = boards.map(({ id }) => id);

      removeBoardsFromAllViews({
        parentBoardId: oldParentId ?? undefined,
        boardsIdsToRemove,
        libraryId: oldLibraryId,
      });
    },
    [addBoardsToAllViews, changeSideBoardsParent, removeBoardsFromAllViews, dispatch, workspaceId],
  );

  const moveBoards = useMutation({
    mutationFn: ({ newParentId, boards = [], library }: MoveItemsToBoardParams) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.batchMoveBoards({
        workspaceId,
        newParentId,
        boardIds: boards.map(({ id }) => id),
        libraryId: library?.id,
      });
    },
    onMutate: ({ newParentId, boards = [], library }: MoveItemsToBoardParams) => {
      const oldParentId = boards[0].parentId;
      const oldLibraryId = boards[0].library?.id;
      const libraryToMoveTo = oldLibraryId === library?.id ? boards[0].library : library;

      handleBoardMove({
        boards,
        newParentId,
        oldParentId,
        oldLibraryId,
        newLibrary: libraryToMoveTo,
      });
    },
    onSuccess: (_, { boards, library, newParentId }) => {
      trackMovedBoards({
        board_ids: boards.map(({ id }) => id),
        new_parent_board_id: newParentId,
        new_library_id: library?.id,
      });
      const currentBoardId = getBoardIdFromPath(window.location.pathname);

      if (currentBoardId) {
        updateBoardStats(currentBoardId);
      }
    },
    onError: (_, { boards, library, newParentId }) => {
      const oldParentId = boards[0].parentId;
      const oldLibraryId = boards[0].library?.id;
      const libraryToMoveTo = oldLibraryId !== library?.id ? boards[0].library : library;

      handleBoardMove({
        boards,
        newParentId: oldParentId,
        oldParentId: newParentId,
        oldLibraryId,
        newLibrary: libraryToMoveTo,
      });
    },
  });

  return {
    moveBoards,
  };
};
