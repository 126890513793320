import { permissionsSelector } from '@air/redux-permissions';

import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseBoardPermissionsParams {
  boardId: string | undefined;
}

export const useBoardPermissions = ({ boardId }: UseBoardPermissionsParams) => {
  const boardPermissions = useAirSelector((st) => permissionsSelector(st, 'boardIds', boardId));

  return {
    boardPermissions,
  };
};
