import { useCallback } from "react";
import { useAnalyticsUtilities } from "../hooks/useAnalyticsUtilities";
import META from "./useTrackMovedBoard.meta";
export const useTrackMovedBoards = ()=>{
    const { track } = useAnalyticsUtilities();
    const trackMovedBoards = useCallback((params)=>{
        track(META.name, params);
    }, [
        track
    ]);
    return {
        trackMovedBoards
    };
};
