import { formatDuration } from '@air/utilities';
import { useCallback, useRef } from 'react';

export const useVideoPreview = () => {
  const videoPreviewRef = useRef<HTMLDivElement>(null);

  const onUpdatePreview = useCallback(({ x }: { x: number }) => {
    if (!videoPreviewRef.current) return null;

    const containerWidth = videoPreviewRef.current.parentElement?.getBoundingClientRect().width || 0;
    const previewWidth = videoPreviewRef.current.getBoundingClientRect().width;

    /**
     * These checks are to ensure the preview doesn't overflow the container.
     */
    if (x < previewWidth / 2) {
      videoPreviewRef.current.style.left = `${previewWidth / 2}px`;
    } else if (x > containerWidth - previewWidth / 2) {
      videoPreviewRef.current.style.left = `${containerWidth - previewWidth / 2}px`;
    } else {
      videoPreviewRef.current.style.left = `${x}px`;
    }

    videoPreviewRef.current.classList.remove('hidden');
  }, []);

  const onUpdatePreviewTimestamp = useCallback(({ duration, position }: { duration: number; position: number }) => {
    if (!videoPreviewRef.current) return null;

    const newTime = position * duration;

    /**
     * If a user holds down the cursor and moves it past the container, the timestamp should not
     * increase past the duration of the video.
     */
    if (duration < newTime) {
      videoPreviewRef.current.dataset.timestamp = formatDuration(duration);
    } else {
      videoPreviewRef.current.dataset.timestamp = formatDuration(newTime);
    }
  }, []);

  const onResetPreview = useCallback(() => {
    if (!videoPreviewRef.current) return null;

    videoPreviewRef.current.classList.add('hidden');
    videoPreviewRef.current.style.left = '0';
  }, []);

  return { onResetPreview, onUpdatePreview, onUpdatePreviewTimestamp, videoPreviewRef };
};
