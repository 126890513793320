import { AssetSearchFilters, Board, ClipAndBoardListItem, Library, ListResponse } from '@air/api/types';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { identity, isEmpty } from 'lodash';
import { useCallback } from 'react';

import { GALLERY_BOARDS, GALLERY_MIXED_DATA } from '~/constants/react-query-keys';
import { getUpdatedBoard } from '~/utils/BoardUtils';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { getRearrangedBoards, getUpdatedBoards, UpdatedBoardBase } from '~/utils/mutateUtils/mutators';
import { getBoardIdFromPath } from '~/utils/PathUtils';

import { useUpdateKanbanData } from './useUpdateKanbanData';

const getQueryKey = ({
  mainKey,
  parentBoardId,
  libraryId,
}: {
  mainKey: string;
  parentBoardId?: Board['id'] | '*';
  libraryId?: Library['id'];
}) => {
  const keyToUpdate: any[] = [mainKey];

  const filters: AssetSearchFilters = {};

  if (parentBoardId && parentBoardId !== '*') {
    filters.board = { is: parentBoardId };
  }

  if (libraryId) {
    filters.library = { is: libraryId };
  }

  if (!parentBoardId && !libraryId) {
    filters.onlyInLibraries = { is: false };
  }

  if (!isEmpty(filters)) {
    keyToUpdate.push({ filters });
  }

  return keyToUpdate;
};

const useMutateBoardsInAllViews = () => {
  const queryClient = useQueryClient();

  const updateCache = useCallback(
    <T>({
      mainKey,
      parentBoardId,
      updateFn,
      libraryId,
    }: {
      mainKey: string;
      parentBoardId?: string;
      libraryId?: string;
      updateFn: (data?: T[]) => T[] | undefined;
    }) => {
      queryClient.setQueriesData<InfiniteData<T>>(
        { queryKey: getQueryKey({ parentBoardId, libraryId, mainKey }) },
        (data) => {
          return data
            ? {
                pages: updateFn(data?.pages) || [],
                pageParams: data?.pageParams,
              }
            : undefined;
        },
      );
    },
    [queryClient],
  );

  /**
   * Use this method to update grouped data in SWR cache
   * @param parentBoardId parent board id. if empty, this method will go through all grouped SWR cache keys
   * @param updateFn function that returns updated data
   */
  const mutateGalleryData = useCallback(
    ({
      updateFn,
      libraryId,
      parentBoardId,
    }: {
      parentBoardId?: string;
      updateFn: (data?: ListResponse<Board>[]) => ListResponse<Board>[] | undefined;
      libraryId?: string;
    }) => {
      updateCache({ mainKey: GALLERY_BOARDS, parentBoardId, updateFn, libraryId });
    },

    [updateCache],
  );

  const mutateTableData = useCallback(
    ({
      parentBoardId,
      libraryId,
      updateFn,
    }: {
      parentBoardId?: string;
      libraryId?: string;
      updateFn: (data?: ListResponse<ClipAndBoardListItem>[]) => ListResponse<ClipAndBoardListItem>[] | undefined;
    }) => updateCache({ mainKey: GALLERY_MIXED_DATA, parentBoardId, updateFn, libraryId }),
    [updateCache],
  );

  return {
    mutateGalleryData,
    mutateTableData,
  };
};

/**
 * Use this hook to refresh boards (refetch) in all views (gallery, table etc)
 */
export const useRefreshBoardsInAllViews = () => {
  const queryClient = useQueryClient();

  /**
   * Use this method to update mixed data in SWR cache
   * @param parentBoardId parent board id. if empty, this method will go through all mixed SWR cache keys
   * @param updateFn function that returns updated data
   */
  const refreshBoardsInAllViews = useCallback(
    (parentBoardId: Board['id']) => {
      Promise.all([
        queryClient.invalidateQueries({ queryKey: getQueryKey({ mainKey: GALLERY_BOARDS, parentBoardId }) }),
        queryClient.invalidateQueries({ queryKey: getQueryKey({ mainKey: GALLERY_MIXED_DATA, parentBoardId }) }),
      ]);
    },
    [queryClient],
  );

  return {
    refreshBoardsInAllViews,
  };
};

/**
 * Use this hook to update single board in all views (gallery, table etc)
 */
export const useUpdateBoardInAllViews = () => {
  const { mutateTableData } = useMutateBoardsInAllViews();
  const queryClient = useQueryClient();

  const updateBoardInAllViews = useCallback(
    (updatedBoard: UpdatedBoardBase) => {
      const parentId = updatedBoard.parentId || '';

      return Promise.all([
        queryClient.setQueriesData<InfiniteData<ListResponse<Board>> | undefined>(
          {
            queryKey: getQueryKey({
              parentBoardId: parentId,
              mainKey: GALLERY_BOARDS,
              libraryId: updatedBoard.library?.id,
            }),
          },
          getUpdatedBoards(updatedBoard),
        ),
        mutateTableData({
          parentBoardId: parentId,
          libraryId: updatedBoard.library?.id,
          updateFn: (data) =>
            data?.reduce((acc, curr) => {
              acc.push({
                ...curr,
                data: curr.data.map((item) => {
                  if (item.type === 'board') {
                    const board = item.data as Board;
                    return {
                      ...item,
                      data:
                        board.id === updatedBoard.id
                          ? getUpdatedBoard({ newBoard: updatedBoard, oldBoard: board })
                          : board,
                    };
                  }
                  return item;
                }),
              });

              return acc;
            }, [] as ListResponse<ClipAndBoardListItem>[]),
        }),
      ]);
    },
    [mutateTableData, queryClient],
  );

  return {
    updateBoardInAllViews,
  };
};

/**
 * Use this hook to remove board from all views (gallery, table etc)
 */
export const useRemoveBoardsFromAllViews = () => {
  const { mutateTableData, mutateGalleryData } = useMutateBoardsInAllViews();
  const { updateBoardStats } = useUpdateBoardStats();
  const { removeKanbanItems } = useUpdateKanbanData();

  const removeBoardsFromAllViews = useCallback(
    ({
      boardsIdsToRemove,
      parentBoardId,
      libraryId,
    }: {
      /*
       * Pass '*' to remove board from all views
       */
      parentBoardId?: string | '*';
      libraryId?: string;
      boardsIdsToRemove: string[];
    }) => {
      const currentBoardId = getBoardIdFromPath(window.location.pathname);
      if (parentBoardId) {
        updateBoardStats(parentBoardId);
      }
      if (currentBoardId && currentBoardId !== parentBoardId) {
        updateBoardStats(currentBoardId);
      }
      Promise.all([
        removeKanbanItems({ boardIds: boardsIdsToRemove }),
        mutateGalleryData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            let totalRemoved = 0;

            const newData = data?.reduce((acc, curr) => {
              const newBoards = curr.data.filter((board) => !boardsIdsToRemove.includes(board.id));
              totalRemoved += curr.data.length - newBoards.length;

              acc.push({
                ...curr,
                data: curr.data.filter((board) => !boardsIdsToRemove.includes(board.id)),
              });
              return acc;
            }, [] as ListResponse<Board>[]);

            return produce(newData, (draft) => {
              draft?.forEach((data) => (data.total = data.total - totalRemoved));
            });
          },
        }),
        mutateTableData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            let totalRemoved = 0;

            const newData = data?.reduce((acc, curr) => {
              const newBoards = curr.data.filter(
                ({ data, type }) => type !== 'board' || !boardsIdsToRemove.includes((data as Board).id),
              );
              totalRemoved += curr.data.length - newBoards.length;

              acc.push({
                ...curr,
                data: newBoards,
              });
              return acc;
            }, [] as ListResponse<ClipAndBoardListItem>[]);

            return produce(newData, (draft) => {
              draft?.forEach((data) => (data.total = data.total - totalRemoved));
            });
          },
        }),
      ]);
    },
    [mutateGalleryData, mutateTableData, removeKanbanItems, updateBoardStats],
  );

  return {
    removeBoardsFromAllViews,
  };
};

/**
 * Use this hook to update rearranged boards in gallery
 */
export const useRearrangeBoardsInGallery = () => {
  const queryClient = useQueryClient();
  /**
   * Use this method to rearrange boards in gallery
   * @param parentBoardId parent board id
   * @param rearrangedBoards all existing boards in new order
   */
  const rearrangeBoardsInGallery = useCallback(
    ({
      parentBoardId,
      libraryId,
      rearrangedBoards,
    }: {
      parentBoardId?: string;
      rearrangedBoards: Board[];
      libraryId?: string;
    }) =>
      queryClient.setQueriesData<InfiniteData<ListResponse<Board>> | undefined>(
        { queryKey: getQueryKey({ parentBoardId, mainKey: GALLERY_BOARDS, libraryId }) },
        getRearrangedBoards(rearrangedBoards),
      ),
    [queryClient],
  );

  return {
    rearrangeBoardsInGallery,
  };
};

/**
 * Use this hook to add board to all views (gallery, table etc)
 */
export const useAddBoardsToAllViews = () => {
  const { mutateGalleryData, mutateTableData } = useMutateBoardsInAllViews();
  const { addItemsToKanbanColumn } = useUpdateKanbanData();
  const { updateBoardStats } = useUpdateBoardStats();
  /**
   * Use this method to add boards to gallery
   * @param parentBoardId parent board id
   * @param boardsToAdd new boards
   */
  const addBoardsToAllViews = useCallback(
    ({
      parentBoardId,
      libraryId,
      boardsToAdd,
    }: {
      parentBoardId?: string;
      libraryId?: string;
      boardsToAdd: Board[];
    }) => {
      const currentBoardId = getBoardIdFromPath(window.location.pathname);

      if (parentBoardId && parentBoardId === currentBoardId) {
        addItemsToKanbanColumn({ boards: boardsToAdd });
        updateBoardStats(parentBoardId);
      }

      return Promise.all([
        mutateGalleryData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            if (data) {
              return produce(data, (draft) => {
                const firstData = draft[0];
                firstData.data = [...boardsToAdd, ...firstData.data];
                firstData.total = firstData.total + boardsToAdd.length;
              });
            } else {
              return [
                {
                  data: [...boardsToAdd],
                  total: boardsToAdd.length,
                  pagination: {
                    cursor: null,
                    hasMore: false,
                  },
                },
              ];
            }
          },
        }),
        mutateTableData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            const boardsData = boardsToAdd.map((board) => ({
              type: 'board',
              data: board,
              id: board.id,
            })) as ClipAndBoardListItem[];

            if (data) {
              return produce(data, (draft) => {
                const firstData = draft[0];
                firstData.data = [...boardsData, ...firstData.data];
                firstData.total = firstData.total + boardsToAdd.length;
              });
            } else {
              return [
                {
                  data: boardsData,
                  total: boardsToAdd.length,
                  pagination: {
                    cursor: null,
                    hasMore: false,
                  },
                },
              ];
            }
          },
        }),
      ]);
    },
    [addItemsToKanbanColumn, updateBoardStats, mutateGalleryData, mutateTableData],
  );

  return {
    addBoardsToAllViews,
  };
};

interface UpdateBoardsInAllViewsParams {
  parentBoardId?: string;
  libraryId?: string;
  boardIds: string[];
  updateFn?: (existingBoard: Board) => Board;
}

/**
 * Use this hook to update boards in all views (gallery, table etc)
 */
export const useUpdateBoardsInAllViews = () => {
  const { mutateTableData, mutateGalleryData } = useMutateBoardsInAllViews();
  const { updateKanbanData } = useUpdateKanbanData();

  const updateBoardsInAllViews = useCallback(
    ({ boardIds, parentBoardId = '', libraryId, updateFn = identity }: UpdateBoardsInAllViewsParams) =>
      Promise.all([
        updateKanbanData({
          boardIds,
          updateBoardFn: updateFn,
        }),
        mutateGalleryData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            const idsSet = new Set(boardIds);
            if (data) {
              return data.map((response) =>
                produce(response, (nextResponse) => {
                  nextResponse.data = nextResponse.data.map((board) => {
                    return idsSet.has(board.id) ? updateFn(board) : board;
                  });
                }),
              );
            }
          },
        }),
        mutateTableData({
          parentBoardId,
          libraryId,
          updateFn: (data) => {
            const idsSet = new Set(boardIds);
            if (data) {
              // NOTE: This may need to match the mutateGalleryData use of .map instead of .forEach
              return data.map((response) =>
                produce(response, (nextResponse) => {
                  nextResponse.data.forEach((item) => {
                    if (item.type === 'board' && idsSet.has((item.data as Board).id)) {
                      item.data = updateFn(item.data as Board);
                    }
                  });
                }),
              );
            }
          },
        }),
      ]),
    [updateKanbanData, mutateGalleryData, mutateTableData],
  );

  return {
    updateBoardsInAllViews,
  };
};
