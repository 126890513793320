import { ScopedPermissions } from '@air/api';
import { permissionsSelector, setPermissionsAction } from '@air/redux-permissions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAirStore } from '~/utils/ReduxUtils';

export const useLibraryPermissionsCache = () => {
  const store = useAirStore();
  const dispatch = useDispatch();

  const getLibraryPermissions = useCallback(
    (libraryId: string) => permissionsSelector(store.getState(), 'libraryIds', libraryId),
    [store],
  );

  const setLibraryPermissions = useCallback(
    ({ libraryId, permissions }: { libraryId: string; permissions: ScopedPermissions | undefined }) =>
      dispatch(
        setPermissionsAction({
          libraryIds: {
            [libraryId]: permissions,
          },
        }),
      ),
    [dispatch],
  );

  return {
    getLibraryPermissions,
    setLibraryPermissions,
  };
};
