import { useAirModal } from "@air/provider-modal";
import noop from "lodash/noop";
import { EnterprisePlanModal } from "~/components/Modals/EnterprisePlanModal";
import { PlansModal } from "~/components/Modals/PlansModal/PlansModal";
import { Routes } from "~/constants/routes";
import { NEW_TAB } from "~/constants/urls";
import { useCurrentWorkspacePermissionsContext } from "~/providers/CurrentWorkspacePermissionsProvider";
import { useSubscriptionContext } from "~/providers/SubscriptionProvider";
import { canManageSubscription } from "~/utils/permissions/workspacePermissions";
export function useFixPlanAction(param) {
    let { onClickAction = noop, onShowPlansModal = noop, action = "upgrade" } = param;
    const [showPlansModal] = useAirModal(PlansModal);
    const [showEnterprisePlanModal] = useAirModal(EnterprisePlanModal);
    const { data: subscription } = useSubscriptionContext();
    const isEnterprisePlan = (subscription === null || subscription === void 0 ? void 0 : subscription.plan) === "enterprise";
    const { data: permissions } = useCurrentWorkspacePermissionsContext();
    const canEditPlan = canManageSubscription(permissions);
    const showPlanUpgradeModal = ()=>{
        if (isEnterprisePlan) {
            return showEnterprisePlanModal();
        }
        showPlansModal();
    };
    const onClick = (event)=>{
        onClickAction();
        if (canEditPlan) {
            event.preventDefault();
            showPlanUpgradeModal();
            onShowPlansModal();
        }
    };
    return canEditPlan ? {
        children: isEnterprisePlan ? "Contact Support" : action === "upgrade" ? "Upgrade Now" : "Reactivate Now",
        onClick,
        href: Routes.workspaceAdmin.subscription,
        rel: undefined,
        target: undefined
    } : {
        children: "Notify Your Admin",
        onClick,
        href: "#",
        rel: "noopener noreferrer",
        target: NEW_TAB
    };
}
