import { AirActionTrackingLocation, useTrackDeletedItems } from '@air/analytics';
import { Tasks } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { BoardDeletionTask, GetBaseMetadata } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreateDeleteBoardTaskParams = GetBaseMetadata<BoardDeletionTask> & {
  trackLocation: AirActionTrackingLocation;
};

export const useCreateDeleteBoardTask = () => {
  const { createTask } = useCreateTask();
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackDeletedItems } = useTrackDeletedItems();

  const createDeleteBoardTask = useCallback(
    async ({ boardIds = [], libraryId, trackLocation }: CreateDeleteBoardTaskParams) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      trackDeletedItems({
        item_ids: boardIds,
        item_total: boardIds.length,
        item_type: 'board',
        location: trackLocation,
      });

      return createTask({
        task: {
          metadata: {
            libraryId,
            boardIds,
          },
          type: 'BoardDeletion',
          workspaceId,
        },
        createTask: () =>
          Tasks.createTask({
            workspaceId,
            task: {
              type: 'ContentRemover',
              args: {
                boardIds,
              },
            },
          }),
      });
    },
    [createTask, currentWorkspace?.id, trackDeletedItems],
  );

  return {
    createDeleteBoardTask,
  };
};
