import { Clips } from "@air/api";
import { useQuery } from "@tanstack/react-query";
export const defaultClipGetOptions = {
    withBoards: true,
    withAncestorsAndThumbnails: true,
    withOpenDiscussionStatus: true,
    numBoardThumbnails: 1
};
export const getClipWithoutWorkspaceIdKey = (param)=>{
    let { clipId, options } = param;
    return [
        "clips",
        {
            clipId,
            ...options
        }
    ];
};
export const useGetClipWithNoWorkspaceId = (param)=>{
    let { clipId, options } = param;
    return useQuery({
        queryKey: clipId ? getClipWithoutWorkspaceIdKey({
            clipId,
            options
        }) : [],
        queryFn: ()=>{
            if (!clipId) {
                throw new Error("clipId is required");
            }
            return Clips.getWithNoWorkspaceId({
                id: clipId,
                options
            });
        },
        enabled: !!clipId
    });
};
