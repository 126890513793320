import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useLibraries } from '~/components/LibraryBeta/hooks/queries/useLibraries';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useRootWorkspaceBoards } from '~/swr-hooks/boards/useRootWorkspaceBoards';

export const BOARD_SELECT_SEARCH_KEY = 'BOARD_SELECT_SEARCH_KEY';

export const getBoardSelectSearchKey = (params: UseBoardSelectSearchParams) => [BOARD_SELECT_SEARCH_KEY, params];

export type UseBoardSelectSearchParams = {
  hasLibraries?: boolean;
  search: string;
  workspaceId?: string;
};

export const useBoardSelectSearch = (params: UseBoardSelectSearchParams) => {
  const key = getBoardSelectSearchKey(params);
  const { data: rootLibraries } = useLibraries({
    enabled: params.hasLibraries,
  });
  const { data: rootBoards } = useRootWorkspaceBoards(params.workspaceId);

  const flattenedRootLibraries = useMemo(() => rootLibraries?.pages.flatMap((page) => page.data), [rootLibraries]);

  const { data: searchBoards } = useQuery({
    queryKey: key,

    queryFn: () => {
      const workspaceId = params.workspaceId;

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return Boards.list({
        limit: 100,
        includeAncestors: true,
        onlyEditable: true,
        search: params.search,
        workspaceId,
      });
    },
    enabled: !!params.workspaceId,
  });

  const boards = useMemo(() => {
    if (params.hasLibraries) {
      if (params.search) {
        return searchBoards?.data.filter((board) => !board.library) ?? [];
      }

      return rootBoards.filter((board) => !board.library);
    }

    if (params.search) {
      return searchBoards?.data ?? [];
    }

    return rootBoards;
  }, [params.hasLibraries, params.search, rootBoards, searchBoards?.data]);

  const librariesBoards = useMemo(() => {
    const libraries: Record<string, Board[]> = {};

    if (params.search && params.hasLibraries) {
      searchBoards?.data.forEach((board) => {
        const libraryId = board.library?.id;

        if (!libraryId) return;

        if (libraries[libraryId]) {
          libraries[libraryId].push(board);
        } else {
          libraries[libraryId] = [board];
        }
      });
    }

    return libraries;
  }, [params.hasLibraries, params.search, searchBoards?.data]);

  const libraries = useMemo(() => {
    if (!params.hasLibraries) {
      return [];
    }

    if (params.search) {
      const uniqueLibraries = new Set();

      return (
        searchBoards?.data
          .filter((board) => {
            if (board.library && !uniqueLibraries.has(board.library.id)) {
              uniqueLibraries.add(board.library.id);
              return true;
            }
            return false;
          })
          .map((board) => board.library!) ?? []
      );
    }

    return flattenedRootLibraries ?? [];
  }, [flattenedRootLibraries, params.hasLibraries, params.search, searchBoards?.data]);

  useFetchObjectsPermissions({
    objects: {
      boardIds: [...boards, ...Object.values(librariesBoards).flat()].map((board) => board.id),
      libraryIds: libraries?.map((library) => library.id),
    },
  });

  return { boards, libraries, librariesBoards };
};
